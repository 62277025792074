<template>
  <el-dialog
    class="wrapper-dialog"
    :visible.sync="dialogVisible"
    custom-class
    append-to-body
    :show-close="false"
    :modalAppendToBody="false"
  >
    <div
      class="py-5 px-3 flex flex-col items-center shadow-lg border rounded-lg border-gray-200"
    >
      <img
        loading="lazy"
        src="https://res.cloudinary.com/komerciaacademico/image/upload/q_auto,f_auto/v1649276085/2264789_x9nlrl.webp"
        alt="icon export"
        width="140"
      />
      <h1 class="text-xl font-bold mt-5">
        {{ $t("text_ventas_exportarMisVentas") }}
      </h1>
      <p class="text-center text-sm mt-2 text-gray-400 px-4">
        {{ $t("text_client_descrip_exportar") }}
      </p>
      <button
        v-if="client && client > 0"
        class="w-full mt-8 border rounded-md bg-indigo-700 text-white px-5 py-2 cursor-pointer hover:bg-green-500"
        @click="exportProducts"
      >
        {{ $t("text_paymentBlock_textbtn_exportar") }}
      </button>

      <button
        class="w-full mt-2 border rounded-md border-red-400 text-red-400 px-5 py-2 cursor-pointer hover:border-red-600 hover:text-red-600"
        @click="dialogVisible = false"
      >
        {{ $t("text_cupones_cerrarVentana") }}
      </button>
    </div>
  </el-dialog>
</template>

<script>
import axios from "axios";
import Papa from "papaparse";

export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    client: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(newValue) {
        this.$emit("update:visible", newValue);
      },
    },
  },
  methods: {
    async exportProducts() {
      try {
        const data1 = {
          currency: this.$store.state.storeData.currency,
          clientId: this.client,
        };
        console.log(this.$store.state.storeData.currency);
        const { data } = await axios({
          method: "GET",
          url: `${this.$configKomercia.urlKomerciaAWS}/api/v1/panel/export-clients/${this.$store.state.storeData.id}`,
          headers: this.$store.state.configAxios.headers,
          params: data1,
        });
        if (data) {
          this.downloadCSV(data);
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    downloadCSV(data) {
      // Convertir los datos CSV a un Blob
      const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "clientes.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style scoped>
.wrapper-dialog >>> .el-dialog__header {
  padding: 0px !important;
}
.wrapper-dialog >>> .el-dialog__body {
  padding: 0px !important;
}
.wrapper-dialog >>> .el-dialog {
  border-radius: 10px;
  width: 350px;
}
</style>
